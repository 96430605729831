import imagePlaceholder from "@shared/assets/image-placeholder.png";
import RecipeIngredient from "./RecipeIngredient";
import { Recipe } from "@shared/types/global/recipe";
import { useMemo, useState } from "react";
import { RecipeIngredient as RecipeIngredientType } from "@shared/types/global/recipe_ingredients";
import { convertUnit } from "@shared/helpers/units";
import Loader from "@shared/components/primitives/Loader";
import Icon from "@shared/components/primitives/Icon";

const RecipeOverviewCard = ({ recipe }: { recipe: Recipe }) => {
  const [activeIngredientId, setActiveIngredientId] = useState<string | null>(null);

  const combinedIngredients: RecipeIngredientType[] = useMemo(() => {
    const ingredientMap = new Map<string, RecipeIngredientType>();

    recipe.recipe_steps?.forEach((step) => {
      step.recipe_ingredients?.forEach((ingredient) => {
        const existingIngredient = ingredientMap.get(ingredient.name);
        if (existingIngredient) {
          let newAmount = ingredient.amount;
          // Convert units if they're different but of the same type
          if (existingIngredient.unit !== ingredient.unit) {
            try {
              newAmount = convertUnit(ingredient.unit, existingIngredient.unit, ingredient.amount);
            } catch (error) {
              // If conversion fails, keep the original unit and amount
              console.warn(`Unable to convert units for ${ingredient.name}: ${error}`);
            }
          }
          existingIngredient.amount += newAmount;
        } else {
          ingredientMap.set(ingredient.name, { ...ingredient });
        }
      });
    });

    return Array.from(ingredientMap.values());
  }, [recipe]);

  return (
    <div className="flex h-full max-h-[1000px] w-full max-w-[400px] flex-col rounded-4 border border-gray-2 bg-panel p-rx-5 pb-rx-3 shadow-4">
      <div className="text-1 font-medium text-gray-10">Overview</div>
      <div className="text-5 font-bold">{recipe.name}</div>
      <div className="relative mb-rx-3 mt-rx-2 flex h-[25vh] max-h-[250px] w-full items-center justify-center overflow-hidden rounded-4">
        <img src={recipe.image_url ?? imagePlaceholder} className="object-cover" />
      </div>
      <div className="text-2">{recipe.description}</div>
      <div className="mt-rx-3 flex min-h-0 flex-grow flex-col gap-rx-2 overflow-y-auto p-rx-1">
        <div className="flex flex-col gap-rx-2">
          {combinedIngredients.length === 0 && (
            <div className="flex flex-col items-center justify-center gap-rx-3 p-rx-3 py-rx-5">
              <Loader size="lg" className="text-accent-9" />
            </div>
          )}
          {combinedIngredients.map((ingredient) => (
            <RecipeIngredient
              key={ingredient.id}
              ingredient={ingredient}
              activeIngredientId={activeIngredientId}
              setActiveIngredientId={setActiveIngredientId}
            />
          ))}
        </div>
      </div>
      <a
        target="_blank"
        href={recipe.url}
        className="mt-rx-2 flex w-full items-center justify-center gap-rx-1 text-1 underline hover:text-blue-11"
      >
        <Icon icon="link" />
        <span>Original Recipe</span>
      </a>
    </div>
  );
};

export default RecipeOverviewCard;
