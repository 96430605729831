import { supabase } from "@shared/context/AuthProvider";
import { Recipe } from "@shared/types/global/recipe";
import { ConnectionResponse } from "@shared/types/global/response";
export const getAllRecipesByUser = async (userId: string): Promise<ConnectionResponse<Recipe[]>> => {
  const { data, error } = await supabase.from("recipes").select("*").eq("created_by_user_id", userId);
  if (error) {
    return { data: null, error: error.message };
  }
  return { data, error };
};

export const convertRecipeByUrl = async (url: string, recipeId?: string): Promise<ConnectionResponse<Recipe>> => {
  const { data, error } = await supabase.functions.invoke("convert-recipe-from-url", {
    body: { url: url, recipeId: recipeId },
  });
  if (error) {
    return { data: null, error: error.message };
  }
  return { data, error };
};

export const deleteRecipe = async (recipeId: string): Promise<ConnectionResponse<Recipe>> => {
  const { data, error } = await supabase.from("recipes").delete().eq("id", recipeId);
  if (error) {
    return { data: null, error: error.message };
  }
  return { data, error };
};
