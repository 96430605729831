import Button from "@shared/components/primitives/Button";
import Icon from "@shared/components/primitives/Icon";
import imagePlaceholder from "@shared/assets/image-placeholder.png";
import RecipeIngredient from "./RecipeIngredient";
import { supabase, useAuth } from "@shared/context/AuthProvider";
import RecipeStepTimer from "./RecipeStepTimer";
import RecipeStepAlert from "./RecipeStepAlert";
import { RecipeStep } from "@shared/types/global/recipe_step";
import { Updater } from "use-immer";
import { Recipe } from "@shared/types/global/recipe";
import { useState } from "react";
import { useToast } from "@shared/context/ToastProvider";

const RecipeStepCard = ({
  recipe,
  recipeStep,
  timerStartTimestamps,
  setTimerStartTimestamps,
}: {
  recipe: Recipe;
  recipeStep: RecipeStep;
  timerStartTimestamps: Record<string, number | null>;
  setTimerStartTimestamps: Updater<Record<string, number | null>>;
}) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [activeIngredientId, setActiveIngredientId] = useState<string | null>(null);
  const [showRegenerateImageButton, setShowRegenerateImageButton] = useState(true);

  const handleRefreshImage = async () => {
    toast({
      title: "Regenerating image...",
      description: "Please wait while we regenerate the image for this step.",
      variant: "info",
    });
    setShowRegenerateImageButton(false);
    const { error } = await supabase.functions.invoke("generate-recipe-step-image", {
      body: { recipe_step_id: recipeStep?.id },
    });
    if (error) {
      console.error(error);
    }
  };

  const handleSetTimestamp = (recipeStepId: string, timestamp: number | null) => {
    if (!recipeStep) return;
    setTimerStartTimestamps((draft) => {
      draft[recipeStepId] = timestamp;
    });
  };

  return (
    <div className="flex h-full max-h-[1000px] w-full max-w-[400px] flex-col rounded-4 border border-gray-2 bg-panel p-rx-5 shadow-4">
      <div className="text-1 font-medium text-gray-10">
        Step {recipeStep.order} of {recipe?.recipe_steps?.length}
      </div>
      <div className="text-5 font-bold">{recipeStep.title}</div>
      <div className="relative mb-rx-3 mt-rx-2 flex h-[25vh] max-h-[250px] w-full items-center justify-center overflow-hidden rounded-4 md:max-h-[200px]">
        <img src={recipeStep.image_url ?? imagePlaceholder} className="object-cover" />
        {user?.id === recipe?.created_by_user_id && showRegenerateImageButton && (
          <Button variant="outline" size="sm" className="absolute right-2 top-2" onClick={handleRefreshImage}>
            <Icon icon="refresh" />
          </Button>
        )}
      </div>
      <div className="text-2">{recipeStep.instruction}</div>
      <div className="mt-rx-3 flex min-h-0 flex-grow flex-col gap-rx-2 overflow-y-auto p-rx-1">
        <div className="flex flex-col gap-rx-2">
          {recipeStep.recipe_ingredients?.map((ingredient) => (
            <RecipeIngredient
              key={ingredient.id}
              ingredient={ingredient}
              activeIngredientId={activeIngredientId}
              setActiveIngredientId={setActiveIngredientId}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-rx-2">
        {recipeStep.alert && <RecipeStepAlert recipeStep={recipeStep} />}
        {recipeStep.timer_minutes !== null && recipeStep.timer_minutes > 0 && (
          <RecipeStepTimer
            recipeStep={recipeStep}
            startTimestamp={timerStartTimestamps[recipeStep.id]}
            timerMinutes={recipeStep.timer_minutes}
            handleSetTimestamp={(timestamp) => handleSetTimestamp(recipeStep.id, timestamp)}
          />
        )}
      </div>
    </div>
  );
};

export default RecipeStepCard;
