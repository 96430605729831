import Button from "@shared/components/primitives/Button";
import Icon from "@shared/components/primitives/Icon";
import { useConfirmation } from "@shared/context/ConfirmationProvider";
import { useEffect, useState, useRef } from "react";
import alarmSound from "../assets/alarm-sound.mp3";
import { RecipeStep } from "@shared/types/global/recipe_step";

const RecipeStepTimer = ({
  recipeStep,
  startTimestamp,
  timerMinutes,
  handleSetTimestamp,
}: {
  recipeStep: RecipeStep;
  startTimestamp: number | null;
  timerMinutes: number;
  handleSetTimestamp: (timestamp: number | null) => void;
}) => {
  const timerSeconds = timerMinutes * 60;

  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState(timerSeconds);
  const { confirmation } = useConfirmation();

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (startTimestamp !== null) {
      const interval = setInterval(() => {
        const updatedTimeRemainingSeconds = timerSeconds - (Date.now() - startTimestamp) / 1000;
        setTimeRemainingSeconds(updatedTimeRemainingSeconds);
        if (updatedTimeRemainingSeconds <= 0) {
          clearInterval(interval);
          handleSetTimestamp(null);
          if (audioRef.current) {
            audioRef.current.play();
          }
          confirmation({
            variant: "info",
            title: "Timer finished",
            description: `The timer has finished for ${recipeStep.title}`,
            confirmText: "Okay",
            onConfirm: () => {
              if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
              }
            },
            hideCancel: true,
          });
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setTimeRemainingSeconds(timerSeconds);
    }
  }, [startTimestamp]);

  const handleStartTimer = () => {
    setTimeRemainingSeconds(timerSeconds);
    handleSetTimestamp(Date.now());
  };

  return (
    <div className="flex items-center justify-between gap-rx-2 rounded-3 border border-blue-8 bg-blue-3 px-rx-3 py-rx-2 text-2 text-blue-9">
      <div className="flex gap-rx-2">
        <Icon icon="clock" size="lg" className="p-rx-1" />
        <div className="flex flex-col">
          <span className="font-medium">
            {timerMinutes} {timerMinutes === 1 ? "minute" : "minutes"}
          </span>
          <span className="text-1 italic">{startTimestamp ? "Counting down" : "Click to start timer"}</span>
        </div>
      </div>
      {startTimestamp ? (
        <div className="flex items-center">
          <div className="inline-flex h-rx-6 w-[70px] items-center justify-end gap-rx-1 rounded-item rounded-r-[0px] border border-blue-9 bg-blue-3 px-rx-3 !text-2 font-bold font-medium text-blue-11">
            <span>{Math.floor(timeRemainingSeconds / 60)}</span>
            <span>:</span>
            <span>
              {Math.floor(timeRemainingSeconds % 60)
                .toString()
                .padStart(2, "0")}
            </span>
          </div>
          <Button className="!rounded-l-[0px]" variant="info" onClick={() => handleSetTimestamp(null)}>
            <Icon icon="refresh" />
          </Button>
        </div>
      ) : (
        <Button variant="info" onClick={handleStartTimer}>
          Start Timer
        </Button>
      )}
      <audio ref={audioRef} src={alarmSound} />
    </div>
  );
};

export default RecipeStepTimer;
