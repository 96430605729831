import { supabase, useAuth } from "@shared/context/AuthProvider";
import { useEffect, useState } from "react";
import TextField from "../primitives/TextField";
import Button from "../primitives/Button";
import Icon from "../primitives/Icon";
import { useToast } from "@shared/context/ToastProvider";
import { useTranslation } from "react-i18next";

const AuthModalContentsSignUp = () => {
  const { setAuthState } = useAuth();
  const { toast } = useToast();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formIsInvalid, setFormIsInvalid] = useState<boolean>(false);

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleSignUp = async () => {
    const validation = validateForm();
    if (!validation) {
      setFormIsInvalid(true);
      return;
    }
    setIsLoading(true);
    const { error } = await supabase.functions.invoke("invite-user", {
      body: { user: { first_name: firstName, last_name: lastName, email } },
    });
    if (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "danger",
      });
    } else {
      setEmailSent(true);
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormIsInvalid(true);
      return false;
    }
    if (!firstName || !lastName || !email) {
      setFormIsInvalid(true);
      return false;
    }
    return true;
  };

  // handle sign up if enter key is pressed
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSignUp();
      }
    };
    window.addEventListener("keypress", handleKeyPress);
    return () => window.removeEventListener("keypress", handleKeyPress);
  }, [email, firstName, lastName]);

  // reset wrong credentials when email or password changes
  useEffect(() => {
    setFormIsInvalid(false);
  }, [email, firstName, lastName]);

  return (
    <>
      {emailSent ? (
        <>
          <div className="text-6 font-bold">{t("features.auth.emailSent")}</div>
          <div className="max-w-72 pb-rx-1 text-center">{t("features.auth.pleaseCheckYourEmail", { email })}</div>
          <Button className="w-72" size="lg" onClick={() => setAuthState("login")}>
            {t("features.auth.backToSignIn")}
          </Button>
        </>
      ) : (
        <>
          <div className="text-6 font-bold">{t("features.auth.welcomeAbroad")}</div>
          <div className="max-w-72 pb-rx-1 text-center">{t("features.auth.pleaseFillInThisInformation")}</div>
          <div className="flex w-72 gap-rx-2">
            <TextField
              inputSize="lg"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              validationState={formIsInvalid ? "invalid" : undefined}
              className="w-1/2"
              placeholder={t("features.auth.firstName")}
            />
            <TextField
              inputSize="lg"
              placeholder={t("features.auth.lastName")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              validationState={formIsInvalid ? "invalid" : undefined}
              className="w-1/2"
            />
          </div>
          <TextField
            inputSize="lg"
            type="email"
            placeholder={t("common.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            validationState={formIsInvalid ? "invalid" : undefined}
            className="w-72"
          />
          {formIsInvalid && (
            <div className="flex w-full items-center space-x-2 pb-rx-2 text-left text-2">
              <Icon icon="exclamation-circle" className="text-red-11" />
              <div className="text-red-11">{t("features.auth.invalidFormDataOrEmail")}</div>
            </div>
          )}
          <Button className="w-72" size="lg" onClick={() => handleSignUp()} isLoading={isLoading}>
            {t("features.auth.createAccount")}
          </Button>
          <Button variant="ghost" className="flex w-72 gap-rx-2 !text-2" size="lg" onClick={() => setAuthState("login")}>
            {t("features.auth.alreadyHaveAnAccount")}
            <span className="font-bold">{t("features.auth.signIn")}</span>
          </Button>
        </>
      )}
    </>
  );
};

export default AuthModalContentsSignUp;
