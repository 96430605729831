import { Routes, Route } from "react-router-dom";
import Auth from "@shared/components/auth/Auth";
import { useAuth } from "@shared/context/AuthProvider";
import Collection from "./features/collections/Collection";
import Recipe from "./features/recipe/Recipe";

function App() {
  const { authenticated } = useAuth();

  if (!authenticated) {
    return <Auth />;
  }

  return (
    <Routes>
      <Route path="/auth/*" element={<Auth />} />
      <Route path="/collection" element={<Collection />} />
      <Route path="/recipe/*" element={<Recipe />} />
      <Route path="*" element={<Collection />} />
    </Routes>
  );
}

export default App;
