import Button from "@shared/components/primitives/Button";
import UserAvatar from "@shared/components/primitives/UserAvatar";
import { useAuth } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import { useToast } from "@shared/context/ToastProvider";
import { useTheme } from "@shared/context/ThemeProvider";
import Icon from "@shared/components/primitives/Icon";
import { useTranslation } from "react-i18next";

const HeaderSignInButton = ({ size = "md" }: { size?: "sm" | "md" | "lg" }) => {
  const { user, setAuthState, logout } = useAuth();
  const { toast } = useToast();
  const { handleShowThemePanel } = useTheme();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const tempToast = () => {
    toast({
      title: t("error.comingSoon"),
      description: t("error.weHaventQuiteGotThereYet"),
      variant: "info",
    });
  };

  return (
    <>
      {!user && (
        <Button size={size} variant="primary" className="!px-rx-4" aria-label="Sign in button" onClick={() => setAuthState("login")}>
          {t("features.auth.signIn")}
        </Button>
      )}
      {user && (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button>
              <UserAvatar user={user} size={size === "lg" ? "md" : "sm"} />
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="w-56" align="end">
            <DropdownMenu.Label className="flex flex-col">
              <span className="font-bold">
                {user?.first_name} {user?.last_name}
              </span>
            </DropdownMenu.Label>
            <DropdownMenu.Separator />
            <DropdownMenu.Group>
              <DropdownMenu.Item onClick={tempToast}>
                <div className="flex w-6 justify-center">
                  <Icon icon="user" />
                </div>
                <span>{t("common.profile")}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={() => navigate("/settings")}>
                <div className="flex w-6 justify-center">
                  <Icon icon="gear" />
                </div>
                <span>{t("common.settings")}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={handleShowThemePanel}>
                <div className="flex w-6 justify-center">
                  <Icon icon="palette" />
                </div>
                <span>{t("common.theme")}</span>
                <DropdownMenu.Shortcut>⇧⌘D</DropdownMenu.Shortcut>
              </DropdownMenu.Item>
            </DropdownMenu.Group>
            <DropdownMenu.Separator />
            <DropdownMenu.Item onClick={tempToast}>
              <div className="flex w-6 justify-center">
                <Icon icon="book" />
              </div>
              <span>{t("common.documentation")}</span>
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => window.open("mailto:support@serial.io", "_blank")}>
              <div className="flex w-6 justify-center">
                <Icon icon="question-circle" />
              </div>
              <span>{t("error.contactSupport")}</span>
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item onClick={() => logout()}>
              <div className="flex w-6 justify-center">
                <Icon icon="sign-out" />
              </div>
              <span>{t("common.logout")}</span>
              <DropdownMenu.Shortcut>⇧⌘E</DropdownMenu.Shortcut>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      )}
    </>
  );
};

export default HeaderSignInButton;
