import HeaderSignInButton from "./HeaderSignInButton";
import { useNavigate } from "react-router-dom";
import logo from "@shared/assets/logo.png";

const Header = ({ title }: { title?: string }) => {
  const navigate = useNavigate();

  return (
    <div
      className="group flex h-rx-8 w-full shrink-0 items-center justify-between border-b border-gray-4 bg-panel p-rx-3 md:px-rx-5"
      onClick={() => navigate("/")}
    >
      <img src={logo} alt="Recipe Cleanup" className="h-8 w-auto" />
      <div className="overflow-hidden text-ellipsis whitespace-nowrap px-3 text-3 font-medium text-gray-11">{title}</div>
      <HeaderSignInButton />
    </div>
  );
};

export default Header;
