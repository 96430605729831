import { createContext, useContext, ReactNode, useState, useRef, useEffect } from "react";
import { Theme } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { updateUser } from "@shared/connections/database";
import { useToast } from "./ToastProvider";
import ThemePanel from "@shared/components/ThemePanel";
import { useDarkMode } from "@shared/hooks/useDarkMode";
import { UserTheme } from "@shared/types/global/user";

interface ThemeInterface {
  theme: UserTheme;
  setTheme: (theme: Partial<UserTheme>) => Promise<void>;
  handleShowThemePanel: () => void;
  portalContainer: HTMLElement | undefined;
}

const defaultContext: ThemeInterface = {
  theme: {
    accent_color: "tomato",
    gray_color: "sand",
    radius: "medium",
    scaling: "100%",
    dark_mode: "light",
    icon_weight: "regular",
    icon_sharpness: "rounded",
  },
  setTheme: () => Promise.resolve(),
  handleShowThemePanel: () => {},
  portalContainer: undefined,
};

const ThemeContext = createContext<ThemeInterface>(defaultContext);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { isDarkMode, set: setIsDarkMode } = useDarkMode();
  const { user, refreshAuth } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const portalContainerRef = useRef<HTMLElement>(null);

  const [portalContainer, setPortalContainer] = useState<HTMLElement | undefined>(undefined);
  const [optimisticUpdateTheme, setOptimisticUpdateTheme] = useState<UserTheme | null>(null);

  const theme = optimisticUpdateTheme ?? user?.theme ?? defaultContext.theme;

  const setTheme = async (theme: Partial<UserTheme>) => {
    if (!user) return;
    const updatedTheme: UserTheme = { ...user?.theme, ...theme };
    setOptimisticUpdateTheme(updatedTheme);
    const { error } = await updateUser(user.id, {
      theme: updatedTheme,
    });
    if (error) {
      toast({
        title: "Error updating theme",
        description: error,
        variant: "danger",
      });
    }
    await refreshAuth();
    setOptimisticUpdateTheme(null);
  };

  useEffect(() => {
    setPortalContainer(portalContainerRef.current ?? undefined);
  }, [portalContainerRef]);

  useEffect(() => {
    switch (theme.dark_mode) {
      case "light":
        setIsDarkMode(false);
        break;
      case "dark":
        setIsDarkMode(true);
        break;
      default:
        break;
    }
  }, [theme.dark_mode, setIsDarkMode]);

  const handleShowThemePanel = () => {
    // add theme-panel=true to the URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("theme-panel", "true");
    setTimeout(() => {
      navigate(`${location.pathname}?${searchParams}`);
    }, 0);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        handleShowThemePanel,
        portalContainer,
      }}
    >
      <Theme
        appearance={theme.dark_mode === "choice" ? (isDarkMode ? "dark" : "light") : theme.dark_mode}
        accentColor={theme.accent_color}
        grayColor={theme.gray_color}
        panelBackground="solid"
        radius={theme.radius}
        scaling={theme.scaling}
      >
        <main id="radix-portal-container" ref={portalContainerRef}>
          {children}
          <ThemePanel />
        </main>
      </Theme>
    </ThemeContext.Provider>
  );
};

// Hook for consuming context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
