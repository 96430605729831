import { useEffect } from "react";
import { useImmer } from "use-immer";
import { RecipeStep } from "@shared/types/global/recipe_step";
import { Recipe } from "@shared/types/global/recipe";
import { RecipeIngredient } from "@shared/types/global/recipe_ingredients";
import { supabase } from "@shared/context/AuthProvider";
import { useStorage } from "@shared/context/StorageProvider";

export const useRealtimeRecipe = (recipeId: string | null) => {
  const [recipe, setRecipe] = useImmer<Recipe | null>(null);
  const [recipeSteps, setRecipeSteps] = useImmer<RecipeStep[]>([]);
  const [recipeIngredients, setRecipeIngredients] = useImmer<RecipeIngredient[]>([]);

  const { urlMap: recipeImagesMap, getStorageObjects } = useStorage();

  useEffect(() => {
    const storageObjectIds = [recipe?.image_storage_object_id, ...recipeSteps.map((s) => s.image_storage_object_id)].filter(
      (url): url is string => !!url,
    );
    getStorageObjects("recipe-images", storageObjectIds);
  }, [recipe?.image_storage_object_id, recipeSteps]);

  const refreshRecipeWithStepsAndIngredients = async (recipeId: string) => {
    const { data: recipes, error: recipesError } = await supabase.from("recipes").select("*").eq("id", recipeId);
    if (recipesError) {
      console.error(recipesError);
    }
    setRecipe(recipes?.[0] ?? null);
    const { data: steps, error: stepsError } = await supabase
      .from("recipe_steps")
      .select("*")
      .eq("recipe_id", recipeId)
      .order("order", { ascending: true });
    if (stepsError) {
      console.error(stepsError);
    }
    setRecipeSteps(steps ?? []);
    const { data: ingredients, error: ingredientsError } = await supabase
      .from("recipe_ingredients")
      .select("*, recipe_step:recipe_step_id!inner(recipe_id)")
      .eq("recipe_step.recipe_id", recipeId);
    if (ingredientsError) {
      console.error(ingredientsError);
    }
    setRecipeIngredients(ingredients ?? []);
  };

  const realtimeSubscribe = (recipeId: string) => {
    if (!supabase) {
      console.error("Supabase client not initialized");
      return;
    }

    // Unsubscribe from changes if already subscribed
    const channel = supabase.channel("db-changes");
    if (channel && supabase) {
      supabase.removeChannel(channel);
    }

    // Subscribe to changes on all redux db tables
    supabase
      .channel("db-changes")
      .on("postgres_changes", { event: "*", schema: "public", table: "recipes", filter: `id=eq.${recipeId}` }, (_payload) =>
        refreshRecipeWithStepsAndIngredients(recipeId),
      )
      .on("postgres_changes", { event: "*", schema: "public", table: "recipe_steps", filter: `recipe_id=eq.${recipeId}` }, (_payload) =>
        refreshRecipeWithStepsAndIngredients(recipeId),
      )
      .subscribe((status: string) => {
        if (status === "SUBSCRIBED") {
          console.log("Successfully subscribed to Redux DB changes.");
        }
      });
  };

  useEffect(() => {
    if (!recipeId) return;
    refreshRecipeWithStepsAndIngredients(recipeId);
    realtimeSubscribe(recipeId);
  }, [recipeId]);

  if (!recipe) return null;

  // Combine the recipe, steps, and ingredients into a single object
  const recipeWithStepsAndIngredients: Recipe = {
    ...recipe,
    image_url: recipe?.image_storage_object_id ? recipeImagesMap[recipe.image_storage_object_id] : undefined,
    recipe_steps: recipeSteps.map((step) => ({
      ...step,
      image_url: step.image_storage_object_id ? recipeImagesMap[step.image_storage_object_id] : undefined,
      recipe_ingredients: recipeIngredients.filter((ingredient) => ingredient.recipe_step_id === step.id),
    })),
  };

  return recipeWithStepsAndIngredients;
};
