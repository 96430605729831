import Icon from "@shared/components/primitives/Icon";
import { RecipeStep } from "@shared/types/global/recipe_step";

const RecipeStepAlert = ({ recipeStep }: { recipeStep: RecipeStep }) => {
  return (
    <div className="flex gap-rx-2 gap-rx-2 rounded-3 border border-amber-8 bg-amber-3 p-rx-2 text-2 text-amber-11">
      <Icon icon="exclamation-triangle" size="lg" className="p-rx-1" />
      <span>{recipeStep.alert}</span>
    </div>
  );
};

export default RecipeStepAlert;
