import { Unit, unitConfig } from "@shared/types/global/recipe_ingredients";

export const convertUnit = (from: Unit, to: Unit, amount: number): number => {
  const fromConfig = unitConfig[from];
  const toConfig = unitConfig[to];
  if (fromConfig.type !== toConfig.type) {
    throw new Error("Cannot convert between different types of units");
  }
  return (amount * fromConfig.conversionFactor) / toConfig.conversionFactor;
};

const commonFractions = [
  { value: 1 / 8, string: "⅛" },
  { value: 1 / 4, string: "¼" },
  { value: 3 / 8, string: "⅜" },
  { value: 1 / 2, string: "½" },
  { value: 5 / 8, string: "⅝" },
  { value: 3 / 4, string: "¾" },
  { value: 7 / 8, string: "⅞" },
  { value: 1, string: "1" },
  { value: 1 / 3, string: "⅓" },
  { value: 2 / 3, string: "⅔" },
];

export const formatUnit = (variant: "fraction" | "decimal", amount: number, fallbackRounding: number = 2): string => {
  const decimalPart = amount - Math.floor(amount);
  const wholePart = Math.floor(amount);
  if (variant === "fraction" && decimalPart === 0) {
    return wholePart.toString();
  }
  const fraction = commonFractions.find((fraction) => Math.abs(fraction.value - decimalPart) < 0.05);
  if (fraction && variant === "fraction") {
    return `${wholePart === 0 ? "" : wholePart}${fraction.string}`;
  }
  return amount.toFixed(fallbackRounding);
};
