import Header from "@shared/components/header/Header";
import Button from "@shared/components/primitives/Button";
import TextField from "@shared/components/primitives/TextField";
import { useAuth } from "@shared/context/AuthProvider";
import { Recipe } from "@shared/types/global/recipe";
import { useEffect, useState } from "react";
import { convertRecipeByUrl, deleteRecipe, getAllRecipesByUser } from "./components/supabase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import imagePlaceholder from "@shared/assets/image-placeholder.png";
import Icon from "@shared/components/primitives/Icon";
import { useConfirmation } from "@shared/context/ConfirmationProvider";
import { useStorage } from "@shared/context/StorageProvider";

const Collection = () => {
  const [recipeUrl, setRecipeUrl] = useState("");
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  const { confirmation } = useConfirmation();
  const { user } = useAuth();
  const { urlMap: recipeImagesMap, getStorageObjects } = useStorage();

  useEffect(() => {
    if (recipes.length > 0) {
      getStorageObjects(
        "recipe-images",
        recipes.map((r) => r.image_storage_object_id).filter((id): id is string => !!id),
      );
    }
  }, [recipes]);

  const navigate = useNavigate();

  const fetchRecipes = async () => {
    if (!user) return;
    const { data, error } = await getAllRecipesByUser(user.id);
    if (error || !data) {
      console.error("Error fetching recipes", error);
    } else {
      setRecipes(data);
    }
  };

  useEffect(() => {
    if (user) {
      console.log("Fetching recipes for user", user.id);
      fetchRecipes();
    }
  }, [user?.id]);

  const handleConvertRecipe = () => {
    const recipeId = uuidv4();
    convertRecipeByUrl(recipeUrl, recipeId);
    navigate(`/recipe/${recipeId}`);
  };

  const handleDeleteRecipe = (e: React.MouseEvent<HTMLButtonElement>, recipe: Recipe) => {
    e.stopPropagation();
    confirmation({
      title: recipe.name,
      description: "Are you sure you want to delete this recipe?",
      onConfirm: async () => {
        await deleteRecipe(recipe.id);
        await fetchRecipes();
      },
      confirmText: "Delete",
      variant: "danger",
    });
  };

  return (
    <div className="flex h-dvh w-screen flex-col">
      <Header />
      <div className="scrollbar-hide flex min-h-0 flex-grow flex-col items-center gap-rx-2 overflow-auto p-rx-3">
        {recipes
          .sort((a, b) => b.created_at.localeCompare(a.created_at))
          .map((recipe) => (
            <div
              className="relative flex max-w-[600px] cursor-pointer gap-rx-3 rounded-2 border border-gray-4 bg-panel p-rx-3 hover:border-gray-5 hover:border-gray-6"
              key={recipe.id}
              onClick={() => navigate(`/recipe/${recipe.id}`)}
            >
              <div className="flex h-28 w-28 shrink-0 items-center justify-center overflow-hidden rounded-2">
                <img
                  src={
                    recipe.image_storage_object_id
                      ? (recipeImagesMap[recipe.image_storage_object_id] ?? imagePlaceholder)
                      : imagePlaceholder
                  }
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col">
                <div className="text-3 font-bold">{recipe.name}</div>
                <div className="line-clamp-3 text-2">{recipe.description}</div>
              </div>
              <Button
                variant="ghost"
                size="sm"
                className="absolute bottom-rx-2 right-rx-2"
                symmetric
                onClick={(e) => handleDeleteRecipe(e, recipe)}
              >
                <Icon icon="trash" />
              </Button>
            </div>
          ))}
        <div className="px-rx-7 py-rx-3 text-center text-2 italic text-gray-8">
          Add a new recipe to your collection by pasting in a URL below
        </div>
      </div>
      <div className="flex gap-rx-2 border-t border-gray-4 p-rx-3">
        <TextField placeholder="Recipe URL" className="w-full" value={recipeUrl} onValueChange={setRecipeUrl} />
        <Button tooltip="Add a new recipe to your collection" onClick={handleConvertRecipe}>
          <Icon icon="wand-magic-sparkles" />
          <span>Create</span>
        </Button>
      </div>
    </div>
  );
};

export default Collection;
