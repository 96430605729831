import { createContext, useContext, ReactNode, useMemo, useRef } from "react";
import { useImmer } from "use-immer";
import { StorageObject } from "@shared/types/global/storage";
import { fetchStorageObject } from "@shared/connections/storage";

interface StorageInterface {
  urlMap: { [storageObjectId: string]: string };
  objectMap: { [storageObjectId: string]: StorageObject | null };
  getStorageObjects: (bucketName: string, storageObjectIds: string[], options?: { imageQuality?: "high" | "medium" | "low" }) => void;
}

const defaultContext: StorageInterface = {
  urlMap: {},
  objectMap: {},
  getStorageObjects: () => {},
};

const StorageContext = createContext<StorageInterface>(defaultContext);

export const StorageProvider = ({ children }: { children: ReactNode }) => {
  const [storageObjectsMap, setStorageObjectsMap] = useImmer<{ [storageObjectId: string]: StorageObject | null }>({});
  const pendingStorageObjectIds = useRef<string[]>([]);

  const downloadNewStorageObject = async (
    bucketName: string,
    storageObjectId: string,
    options?: { imageQuality?: "high" | "medium" | "low" },
  ) => {
    const { data: storageObject } = await fetchStorageObject(bucketName, storageObjectId, {
      asObject: true,
      imageQuality: options?.imageQuality,
    });
    if (!storageObject) return;
    setStorageObjectsMap((draft) => {
      draft[storageObjectId] = storageObject as StorageObject;
    });
  };

  const getStorageObjects = (bucketName: string, storageObjectIds: string[], options?: { imageQuality?: "high" | "medium" | "low" }) => {
    storageObjectIds.forEach((id) => {
      if (storageObjectsMap[id] === undefined && !pendingStorageObjectIds.current.includes(id)) {
        pendingStorageObjectIds.current.push(id);
        downloadNewStorageObject(bucketName, id, options);
      }
    });
  };

  const urlMap = useMemo(() => {
    const map: { [storageObjectId: string]: string } = {};
    for (const [storageObjectId, storageObject] of Object.entries(storageObjectsMap)) {
      if (storageObject?.url) {
        map[storageObjectId] = storageObject.url;
      }
    }
    return map;
  }, [storageObjectsMap]);

  return (
    <StorageContext.Provider
      value={{
        urlMap,
        objectMap: storageObjectsMap,
        getStorageObjects,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

// Hook for consuming context
export const useStorage = () => {
  const context = useContext(StorageContext);
  if (!context) {
    throw new Error("useStorage must be used within a StorageProvider");
  }
  return context;
};
