import { RecipeStep } from "./recipe_step";
import { TimestampTz } from "./timestamp";
import { UUIDv4 } from "./uuid";

export type Unit =
  | "m_grams"
  | "m_kilograms"
  | "m_ounces"
  | "m_pounds"
  | "v_milliliters"
  | "v_liters"
  | "v_teaspoons"
  | "v_tablespoons"
  | "v_ounces"
  | "v_cups"
  | "v_pints"
  | "v_quarts"
  | "v_gallons"
  | "c_count"
  | "x_non_numerical";

export const units: Unit[] = [
  "m_grams",
  "m_kilograms",
  "m_ounces",
  "m_pounds",
  "v_milliliters",
  "v_liters",
  "v_teaspoons",
  "v_tablespoons",
  "v_ounces",
  "v_cups",
  "v_pints",
  "v_quarts",
  "v_gallons",
  "c_count",
  "x_non_numerical",
];

export type UnitType = "mass" | "volume" | "count" | "non_numerical";

export const unitConfig: Record<
  Unit,
  {
    abbreviation: string;
    type: UnitType;
    conversionFactor: number;
    baseUnit: Unit;
    preferFractional?: boolean;
  }
> = {
  m_grams: {
    abbreviation: "g",
    type: "mass",
    conversionFactor: 1,
    baseUnit: "m_grams",
    preferFractional: false,
  },
  m_kilograms: {
    abbreviation: "kg",
    type: "mass",
    conversionFactor: 1000,
    baseUnit: "m_grams",
    preferFractional: false,
  },
  m_ounces: {
    abbreviation: "oz",
    type: "mass",
    conversionFactor: 28.3495,
    baseUnit: "m_grams",
    preferFractional: true,
  },
  m_pounds: {
    abbreviation: "lb",
    type: "mass",
    conversionFactor: 453.592,
    baseUnit: "m_grams",
    preferFractional: true,
  },
  v_milliliters: {
    abbreviation: "ml",
    type: "volume",
    conversionFactor: 1,
    baseUnit: "v_milliliters",
    preferFractional: false,
  },
  v_liters: {
    abbreviation: "l",
    type: "volume",
    conversionFactor: 1000,
    baseUnit: "v_milliliters",
    preferFractional: false,
  },
  v_teaspoons: {
    abbreviation: "tsp",
    type: "volume",
    conversionFactor: 4.92892,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  v_tablespoons: {
    abbreviation: "tbsp",
    type: "volume",
    conversionFactor: 14.7868,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  v_ounces: {
    abbreviation: "fl oz",
    type: "volume",
    conversionFactor: 29.5735,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  v_cups: {
    abbreviation: "cup",
    type: "volume",
    conversionFactor: 236.588,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  v_pints: {
    abbreviation: "pt",
    type: "volume",
    conversionFactor: 473.176,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  v_quarts: {
    abbreviation: "qt",
    type: "volume",
    conversionFactor: 946.353,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  v_gallons: {
    abbreviation: "gal",
    type: "volume",
    conversionFactor: 3785.41,
    baseUnit: "v_milliliters",
    preferFractional: true,
  },
  c_count: {
    abbreviation: "",
    type: "count",
    conversionFactor: 1,
    baseUnit: "c_count",
    preferFractional: false,
  },
  x_non_numerical: {
    abbreviation: "",
    type: "non_numerical",
    conversionFactor: 1,
    baseUnit: "x_non_numerical",
    preferFractional: false,
  },
};

export interface RecipeIngredient {
  id: UUIDv4;
  created_at: TimestampTz;
  name: string;
  amount: number;
  amount_non_numerical?: string | null;
  unit: Unit;
  recipe_step_id: UUIDv4;
  // Foreign Keys:
  recipe_step?: RecipeStep;
}

export const generateBlankRecipeIngredient = (ingredient?: Partial<RecipeIngredient>): RecipeIngredient => {
  const now = new Date().toISOString();
  return {
    id: "",
    created_at: now,
    name: "",
    amount: 0,
    amount_non_numerical: null,
    unit: "m_grams",
    recipe_step_id: "",
    ...ingredient,
  };
};
