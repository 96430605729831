import Icon from "@shared/components/primitives/Icon";
import { useMemo, useState } from "react";
import Select from "@shared/components/primitives/Select";
import { RecipeIngredient as RecipeIngredientType, Unit, unitConfig, units } from "@shared/types/global/recipe_ingredients";
import { convertUnit, formatUnit } from "@shared/helpers/units";

const RecipeIngredient = ({
  ingredient,
  activeIngredientId,
  setActiveIngredientId,
}: {
  ingredient: RecipeIngredientType;
  activeIngredientId: string | null;
  setActiveIngredientId: (id: string | null) => void;
}) => {
  const [displayedUnit, setDisplayedUnit] = useState<Unit>(ingredient.unit);

  const displayedAmount = useMemo(() => {
    return convertUnit(ingredient.unit, displayedUnit, ingredient.amount);
  }, [ingredient.unit, displayedUnit, ingredient.amount]);

  const unitOptions = useMemo(() => {
    return units.filter((unit) => unitConfig[unit].type === unitConfig[ingredient.unit].type);
  }, [ingredient.unit]);

  const isConvertableUnit = useMemo(() => {
    return ["volume", "mass", "length"].includes(unitConfig[displayedUnit].type);
  }, [displayedUnit]);

  // Note: Swiper conflicts with the onClick event of the Select.Trigger, so we need to use a separate function to handle the open state
  // When we bring in this function and stopPropagation, theres a second order bug where the dropdown under the select triggers when the select box closes
  // That's why we need to implement the activeIngredientId state to properly in the parent for this to work
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (activeIngredientId === null) setActiveIngredientId(ingredient.id);
  };

  return (
    <div key={ingredient.id} className="flex items-center justify-between gap-rx-2 text-2">
      <div className="flex items-center gap-rx-2">
        <Icon icon="circle" size="2xs" className="text-accent-9" />
        <span>{ingredient.name}</span>
      </div>
      <div className="flex items-center gap-rx-2">
        <Select.Root
          open={activeIngredientId === ingredient.id}
          onOpenChange={(open) => setActiveIngredientId(open ? ingredient.id : null)}
          value={displayedUnit}
          onValueChange={(value) => setDisplayedUnit(value as Unit)}
        >
          <Select.Trigger
            className="w-28 !gap-0 overflow-hidden !p-0 !text-2 text-gray-11 !opacity-100 disabled:bg-gray-2"
            size="sm"
            chevron={false}
            disabled={!isConvertableUnit}
            onClick={handleOpen}
          >
            {ingredient.unit !== "x_non_numerical" && (
              <div className="flex h-full min-w-0 flex-grow bg-gray-2 px-2 pr-rx-1 font-medium">
                {formatUnit(
                  unitConfig[displayedUnit].preferFractional ? "fraction" : "decimal",
                  displayedAmount,
                  isConvertableUnit ? 2 : 0,
                )}
              </div>
            )}
            <div className={`h-full w-12 ${ingredient.unit === "x_non_numerical" ? "px-2" : " border-l border-gray-4"}`}>
              {isConvertableUnit ? (
                <Select.Value />
              ) : (
                <div>{ingredient.unit === "x_non_numerical" ? ingredient.amount_non_numerical : "-"}</div>
              )}
            </div>
          </Select.Trigger>
          <Select.Content>
            {unitOptions.map((unit) => (
              <Select.Item key={unit} value={unit}>
                {unitConfig[unit].abbreviation}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </div>
    </div>
  );
};

export default RecipeIngredient;
